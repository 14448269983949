<template>
  <PortalCard :id="componentId">
    <template #default>
      <div class="loading-container">
        <ProgressIcon
          :color="`var(--kui-color-text-primary, ${KUI_COLOR_TEXT_PRIMARY})`"
          :size="KUI_ICON_SIZE_70"
        />

        <p class="loading-text">
          {{ message || t('labels.loading') }}
        </p>
      </div>
    </template>
  </PortalCard>
</template>

<script setup lang="ts">
import { ProgressIcon } from '@kong/icons'
import { KUI_COLOR_TEXT_PRIMARY, KUI_ICON_SIZE_70 } from '@kong/design-tokens'

const { t } = useI18n()

const props = defineProps({
  /** Loading message. Defaults to "Loading..." */
  message: {
    type: String,
    default: '',
  },
  /** Provide custom CSS rules, scoped to this component */
  styles: {
    type: String,
    required: false,
    default: '',
  },
})

// Inject any custom `props.styles` scoped by the `componentId` into the document head
const { componentId } = useCustomStyles(computed(() => props.styles), useAttrs().id as string)
</script>

<style scoped lang="scss">
.loading {
  &-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: var(--kui-space-70, $kui-space-70);
    justify-content: center;
  }

  &-text {
    font-size: var(--kui-font-size-50, $kui-font-size-50);
    font-weight: var(--kui-font-weight-medium, $kui-font-weight-medium);
  }
}
</style>
